<template lang="pug">
TheModal(:show="show" @update:show="onClose" title="Заполните данные о судне")
	BaseGroup.mb-4(title="Основная информация")
		template(v-for="(field, key) in boat" :key="key")
			BaseSelect(
				v-if="field.options"
				:options="field.options"
				:placeholder="field.placeholder"
				v-model:option="field.value"
				@update:option="changeType"
			)
			BaseInput(
				v-else
				:placeholder="field.placeholder"
				v-model="field.value"
			)
	//BaseGroup.mb-4(title="Склад" icon="plus" @onClick="createWarehouse")
	//	.grid.grid--2
	//		.flex.flex--aic(
	//			v-for="(item, id) in warehouses"
	//			:key="id"
	//		)
	//			BaseInput(
	//				placeholder="Название"
	//				v-model="item.name"
	//			)
	//			IconTrash.pointer.ml-2(@click="deleteWarehouse(id)")
	.flex.flex--jce
		BaseButton(@click="createBoat") Сохранить
</template>

<script>
import { onMounted, reactive, watch } from 'vue'

import TheModal from '../TheModal'
import TheBook from '../TheBook'
import TheList from '../TheList'

import BaseGroup from '../ui/BaseGroup'
import BaseInput from '../ui/BaseInput'
import BaseSelect from '../ui/BaseSelect'
import BaseButton from '../ui/BaseButton'

import IconTrash from '../ui/icons/IconTrash'
import { useApi } from '@/modules/api'

export default {
	name: 'ModalBoat',
	components: { IconTrash, BaseButton, BaseSelect, BaseInput, BaseGroup, TheList, TheModal, TheBook },
	props: {
		show: {
			type: [String, Boolean],
			required: true
		}
	},
	emits: ['update:show', 'create', 'edit'],
	setup (props, { emit }) {
		const boat = reactive({
			shipType: {
				placeholder: 'Тип судна',
				options: {},
				value: ''
			},
			rfid: {
				placeholder: 'RFID',
				value: ''
			},
			name: {
				placeholder: 'Наименование',
				value: ''
			},
			shipNumber: {
				placeholder: 'Номер судна',
				value: ''
			},
			waterDisplacement: {
				placeholder: 'Водоизмещение',
				value: ''
			},
			description: {
				placeholder: 'Данные о регистрации',
				value: ''
			}
		})

		const changeType = (value) => {
			boat.shipType.value = value
		}

		onMounted(async () => {
			const { get, data } = useApi('/ship/shipType')

			await get()

			data.value.content.forEach(({ id, name }) => {
				boat.shipType.options[id] = name
			})
		})

		watch(
			() => props.show,
			async (value) => {
				if (typeof value === 'string') {
					const { get, data } = useApi(`/ship/${value}`)

					await get()

					const { description, id, name, registerDate, rfid, shipNumber, shipStocks = [], shipType, waterDisplacement, workerRegistrer } = data.value

					changeType(String(shipType.id))

					shipStocks.forEach(({ id, name }) => {
						createWarehouse(id, name)
					})

					Object.entries({ description, id, name, registerDate, rfid, shipNumber, waterDisplacement, workerRegistrer }).forEach(([key, value]) => {
						if (boat[key]) {
							boat[key].value = value
						}
					})
				}
			}
		)

		const warehouses = reactive({})

		const createWarehouse = async (id = +(new Date()), name = '') => {
			warehouses[id] = { name }
		}

		const deleteWarehouse = async (id) => {
			if (id.includes('-')) {
				const { del, error } = useApi(`/ship/shipStock/${id}`)

				await del()

				if (!error.value) {
					delete warehouses[id]
				}
			} else {
				delete warehouses[id]
			}
		}

		const createBoat = () => {
			const body = {
				workerRegistrer: {
					id: JSON.parse(localStorage.account).id
				},
				registerDate: new Date().toISOString(),
				shipStocks: Object.entries(warehouses).map(([id, item]) => {
					const stock = { name: item.name }

					if (id.includes('-')) {
						stock.id = id
					}

					return stock
				})
			}

			Object.entries(boat).forEach(([key, item]) => {
				body[key] = key !== 'shipType'
					? item.value
					: {
						id: item.value
					}
			})

			if (typeof props.show === 'string') {
				emit('edit', {
					...body,
					id: props.show
				})
			} else {
				emit('create', body)
			}

			onClose()
		}

		const onClose = () => {
			emit('update:show', false)

			Object.keys(warehouses).forEach(key => {
				delete warehouses[key]
			})

			Object.keys(boat).forEach(key => {
				boat[key].value = ''
			})
		}

		return {
			boat,
			changeType,

			warehouses,
			createWarehouse,
			deleteWarehouse,

			createBoat,
			onClose
		}
	}
}
</script>
