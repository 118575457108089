import { useApi } from '../api'
import { reactive } from 'vue'
import { useAlert } from './useAlert'

export const useWorkers = ({ createWorkerApi, deleteEndpoint, isNew, updateWorkerApi }) => {
	const { errorAlert, infoAlert } = useAlert()

	const workers = reactive({})

	const setWorker = ({ id, title, status, postId }) => {
		workers[id] = { title, status, postId }
	}

	const updateWorker = () => {
		updateWorkerApi()
			.then((workerList) => {
				Object.keys(workers).forEach(key => delete workers[key])
				Object.entries(workerList).forEach(([key, value]) => {
					workers[key] = value
				})
			}).catch((error) => {
				errorAlert(error)
			})
	}

	const deleteField = (id) => {
		delete workers[id]
	}

	const createWorker = async ({ workerId, postId, title, status }) => {
		if (!isNew) {
			const id = await createWorkerApi({ workerId, postId })

			if (id) {
				infoAlert('Данные о сотруднике успешно добавлены')
				await updateWorker()
			} else {
				errorAlert('Ошибка при добавлении сотрудника!')
			}
		} else {
			setWorker({ id: workerId, title, status, postId })
		}
	}

	const deleteWorker = async (id) => {
		if (!isNew) {
			const { del, error } = useApi(deleteEndpoint + '/')

			await del(id)

			if (error.value) {
				errorAlert(`Ошибка в удалении сотрудника! ${error.value?.message}`)
			} else {
				deleteField(id)

				infoAlert('Сотрудник успешно удален')
			}
		} else {
			deleteField(id)
		}
	}

	return {
		workers,
		setWorker,
		createWorker,
		deleteWorker
	}
}
