import formattedDate from '../../utils/formattedDate'
import { reactive } from 'vue'

export const useFishes = () => {
	const lib = {
		fishName: 'Рыба',
		fishSort: 'Сорт',
		receptionPointName: 'Пункт приёма',
		registerDate: 'Дата',
		rfid: 'RFID',
		status: 'Статус',
		weight: 'Вес',
		workerFIO: 'Работник'
	}

	const fishes = reactive([])

	const setFishes = (array) => {
		array.forEach(fishFields => {
			const fish = {}
			Object.entries(fishFields).forEach(([key, value]) => {
				if (lib[key]) {
					fish[key] = {
						title: lib[key],
						value: key === 'registerDate' ? formattedDate(value) : value
					}
				}
			})

			const headFields = ['registerDate', 'fishName', 'weight']
			const fields = []

			Object.entries(fish).forEach(([key, value]) => {
				if (!headFields.includes(key)) {
					fields.push(value)
				}
			})

			const head = []

			headFields.forEach(field => {
				const value = fish[field]?.value

				if (value) {
					head.push(field === 'weight' ? `${value}кг` : value)
				}
			})
			fishes.push({
				head: head.join(' / '),
				fields
			})
		})
	}

	return {
		fishes,
		setFishes
	}
}
