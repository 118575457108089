export default timestamp => {
	const splitSymbol = () => {
		if (timestamp?.includes('T')) return 'T'
		if (timestamp?.includes(' ')) return ' '
		return null
	}

	const splitter = splitSymbol()

	if (splitter) {
		const [year, month, day] = timestamp.split(splitter)[0].split('-')

		return `${day}.${month}.${year}`
	}

	return timestamp
}
