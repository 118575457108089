<template lang="pug">
TheDetail(
	:groups="groups"
	title="Плашкоут"
	title-new="Создать плашкоут"
	:deleteButtonDisabled="isSuperAdmin"
	@showBoat="showBoat"
	@deleteBoat="deleteBoat"
	@remove="removeBoat"
	@save="saveBoat"
)
ModalBoat(v-model:show="modals.boat" @create="createBoat" @edit="editBoat")
ModalWorkers(v-model:show="modals.workers" endpoint="receptionPoint" :filter="{filterValue: ['OPERATOR'], filterKey: 'authorities', path: 'name'}" @create="createWorker")
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useApi } from '../modules/api'

import { useDetail } from '../modules/composition/useDetail'
import { useBasic } from '../modules/composition/inputs/useBasic'
import { useWorkers } from '../modules/composition/useWorkers'
import { useShips } from '../modules/composition/useShips'
import { useFishes } from '../modules/composition/useFishes'

import TheDetail from '../components/TheDetail'

import ModalBoat from '../components/modals/ModalBoat'
import ModalWorkers from '../components/modals/ModalWorkers'

export default {
	name: 'BoatDetail',
	components: {
		TheDetail,
		ModalBoat,
		ModalWorkers
	},
	setup () {
		const allFactoryList = ref({})
		const { idDetail, isNew, notFoundDetail, saveDetail, removeDetail } = useDetail()
		const factorySearcher = (searchString) => {
			const map = {}
			Object.values(allFactoryList.value).forEach((value) => {
				if (searchString) {
					if (value.name.toLowerCase().includes(searchString.toLowerCase())) {
						map[value.id] = value.name
					}
				} else {
					map[value.id] = value.name
				}
			})
			return map
		}
		const { basic, setBasic, bodyBasic } = useBasic({ factorySearcher: factorySearcher, factoryDescription: 'Если указан завод, плашкоут будет использоваться для приемки на НРС' })
		const checkSuperAdmin = () => {
			return !!localStorage.account && JSON.parse(localStorage.account).roles?.ADMIN
		}
		const isSuperAdmin = ref(checkSuperAdmin())
		const saveBoat = async () => {
			await saveDetail({
				endpoint: '/receptionPoint/create',
				body: bodyBasic(),
				workers,
				createWorkerApi
			})
		}

		const removeBoat = async () => {
			await removeDetail(`/receptionPoint/${idDetail}`)
		}

		const createWorkerApi = async ({ id, workerId, postId }) => {
			const { post, data } = useApi(`/receptionPoint/${idDetail}/workers`)

			await post({
				receptionPoint: { id: id || idDetail },
				worker: { id: workerId },
				workerPost: { id: postId }
			})

			return data.value?.id
		}

		const updateWorkerApi = () => {
			return new Promise((resolve, reject) => {
				(async () => {
					try {
						const { get, data, error } = useApi(`/receptionPoint/${idDetail}/workers`)

						await get()

						if (error.value) {
							throw new Error('Не удалось обновить список работников')
						}

						const workerList = data.value.reduce((acc, worker) => {
							const { id, middleName, name, surname, postName: status } = worker
							acc[id] = { id, title: [middleName, name, surname].filter(Boolean).join(' '), status }
							return acc
						}, {})
						resolve(workerList)
					} catch (error) {
						reject(error)
					}
				})()
			})
		}

		const { workers, setWorker, createWorker, deleteWorker } = useWorkers({
			updateWorkerApi,
			createWorkerApi,
			deleteEndpoint: `/receptionPoint/${idDetail}/workers`,
			idDetail,
			isNew
		})

		const { getShipId, setShips, createBoat, editBoat, deleteBoat } = useShips({
			idDetail,
			field: 'receptionPoint',
			createEndpoint: '/receptionPoint/createReceptionPoint2ship',
			deleteEndpoint: '/receptionPoint/deleteReceptionPoint2ship'
		})

		const { fishes, setFishes } = useFishes()

		const showBoat = id => {
			modals.boat = getShipId(id)
		}

		const groups = reactive({
			main: {
				title: 'Общие данные',
				isNew: true,
				grid: 3,
				inputs: basic
			},
			workers: {
				title: 'Работники',
				isNew: true,
				grid: 2,
				icon: 'plus',
				onClick: () => {
					modals.workers = true
				},
				count: computed(() => Object.keys(groups.workers.cards).length),
				iconCard: 'user',
				button: 'Удалить',
				buttonClick: deleteWorker,
				cards: workers
			},
			events: {
				title: 'События',
				hide: computed(() => !groups.events.collapses.length),
				hideNew: true,
				grid: 2,
				collapses: fishes
			}
		})

		const modals = reactive({
			boat: false,
			workers: false
		})

		const { get: getMain, data: dataMain, error } = useApi(`/receptionPoint/${idDetail}`)
		const { get: getWorkers, data: dataWorkers } = useApi(`/receptionPoint/${idDetail}/workers`)
		const { get: getEvents, data: dataEvents } = useApi(`/receptionPoint/${idDetail}/actions`)
		const { get: getFactory, data: dataFactory } = useApi('/factory/all')

		onMounted(async () => {
			if (!isNew) {
				await Promise.all([
					getMain(),
					getWorkers(),
					getEvents(),
					getFactory()
				])

				if (error.value) return notFoundDetail()

				const {
					name,
					address
				} = dataMain.value

				const factory = {
					value: dataMain.value.factory?.id || null,
					search: dataMain.value.factory?.name || ''
				}

				setBasic({ name, address, factory })
				// setShips(listOfShips)

				dataWorkers.value.forEach(work => {
					const { id, name, surname, middleName, postName: status } = work
					setWorker({ id, title: `${surname || ''} ${name || ''} ${middleName || ''}`, status })
				})

				setFishes(dataEvents.value.map(fish => {
					const { action: status, boardCompany: receptionPointName, date: registerDate, fishName, rfid, sort: fishSort, worker: workerFIO, weight } = fish
					return { status, receptionPointName, registerDate, fishName, rfid, fishSort, weight, workerFIO }
				}))
			} else {
				await getFactory()
				setBasic({ name: '', address: '', factory: null })
			}
			dataFactory.value.content.forEach(({ id, name }) => {
				allFactoryList.value[id] = { id, name }
			})
		})

		return {
			saveBoat,
			removeBoat,
			showBoat,

			createWorker,

			getShipId,
			setShips,
			createBoat,
			editBoat,
			deleteBoat,

			groups,
			modals,
			isSuperAdmin
		}
	}
}
</script>
