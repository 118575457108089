import { useApi } from '../api'
import { reactive } from 'vue'
import { useAlert } from './useAlert'

export const useShips = ({ idDetail, field, createEndpoint, deleteEndpoint }) => {
	const { infoAlert, errorAlert } = useAlert()

	const ships = reactive({})
	const idToShipId = {}
	const shipIdToId = {}

	const getShipId = id => idToShipId[id]
	const getId = shipId => shipIdToId[shipId]

	const dictionary = {
		name: 'Название',
		shipNumber: 'Номер',
		rfid: 'RFID',
		workerRegisterFIO: 'Работник'
	}

	const getRussianKeys = (fields) => {
		const entity = {}
		Object.entries(fields).forEach(([key, value]) => {
			entity[dictionary[key] || key] = value
		})
		return entity
	}

	const setShip = ({ id, shipId, name, rfid, workerRegisterFIO, shipNumber }) => {
		idToShipId[id] = shipId
		shipIdToId[shipId] = id
		ships[id] = getRussianKeys({ name, rfid, shipNumber, workerRegisterFIO })
	}

	const updateShip = ({ id, name, rfid, shipNumber, workerRegisterFIO }) => {
		ships[id] = getRussianKeys({ name, rfid, shipNumber, workerRegisterFIO })
	}

	const setShips = (list) => {
		try {
			list.forEach(({ id, ship_id: shipId, name, rfid, workerRegisterFIO, shipNumber }) => {
				setShip({ id, shipId, name, rfid, shipNumber, workerRegisterFIO })
			})
		} catch (e) {
			console.warn('Errr!\n' + e)
		}
	}

	const createBoat = async (body) => {
		try {
			const { post: createShip, data: dataShip } = useApi('/ship/create')
			const { post: createMain, data: dataMain } = useApi(createEndpoint)

			await createShip(body)

			const ship = dataShip.value

			await createMain({
				[field]: { id: idDetail },
				ship
			})

			infoAlert('Судно успешно создано')

			let workerRegisterFIO = ''

			try {
				const { get, data } = useApi(`worker/${JSON.parse(localStorage.account).id}`)

				await get()

				const { surname, name, middleName } = data.value

				workerRegisterFIO = `${surname} ${name} ${middleName}`
			} catch (e) {
				console.warn('Не удалось получить имя сотрудника\n' + e)
			}

			const { id, name, rfid, shipNumber } = ship

			setShip({
				id: dataMain.value.id,
				shipId: id,
				name,
				rfid,
				shipNumber,
				workerRegisterFIO
			})
		} catch (e) {
			errorAlert(`Ошибка в сохранении судна! ${e?.response?.message}`)
		}
	}

	const editBoat = async (body) => {
		const { post, error, data } = useApi('/ship/create')

		await post(body)

		if (error.value) {
			errorAlert(`Ошибка в изменении информации о судне! ${error.value?.message}`)
		} else {
			const { id, name, rfid, shipNumber, workerRegistrer: { surname, name: nameWorker, middleName } } = data.value
			updateShip({ id: getId(id), name, rfid, shipNumber, workerRegisterFIO: `${surname} ${nameWorker} ${middleName}` })
			infoAlert('Информация о судне успешно обновлена')
		}
	}

	const deleteBoat = async (id) => {
		const { del, error } = useApi(deleteEndpoint + '/')

		await del(id)

		if (error.value) {
			errorAlert(`Ошибка в удалении судна! ${error.value?.message}`)
		} else {
			delete ships[id]

			infoAlert('Судно успешно удалено')
		}
	}

	return {
		ships,
		getShipId,
		setShips,
		createBoat,
		editBoat,
		deleteBoat
	}
}
